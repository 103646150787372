import React from "react"
import Layout from "../components/layout"
import HearFromYouForm from "../components/forms/hearfromyou"
import circle1 from "../images/Number-1.png"
import circle2 from "../images/Number-2.png"
import circle3 from "../images/Number-3.png"
import HeroImageContainer from '../components/heroImage'
import prostateMriHome from "../images/01-Hero-Prostate-MRI-Page.jpg"
import prostateMriHeading from "../images/prostate-mri-first.svg"
import drPrincenthal from "../images/02_Dr-Princenthal-with-Screens.jpg"
import mriPoints from "../images/05-MRI-Data-Points-Couple-Circle_1000x1000.jpg"
import physicianIcon from "../images/Physician-Icon.png"
import accuracyMRI from "../images/accuracy-MRI.png"
import accuracyBiopsy from "../images/accuracy-Biopsy.png"

let title = `Prostate MRI | Imaging Center`
let desc = `Our Prostate MRI program offers advanced prostate cancer detection, state of the art imaging center and an alternative to High Intensity Focused Ultrasound (HIFU).`

const ProstateMRI = () => {
    const handleLearnMoreClick = (event) => {
        event.preventDefault(); // Prevent the default anchor link behavior
    
        const mriStartSection = document.getElementById('mriStart'); // Get the target section
        if (!mriStartSection) return; // Guard clause in case the element doesn't exist
    
        const offsetTop = mriStartSection.offsetTop - 100; // Calculate the top position and subtract 100 pixels
    
        window.scrollTo({
            top: offsetTop,
            behavior: 'smooth'
        });
    };
    return (
        <>
        <Layout page={title} desc={desc} pageClass={"MRI"} className="MRI">
            <div className="inside-page">
                <div className="hero">
                    <div className="overlay">
                        <div className="container-inner-mri">
                            <img src={prostateMriHeading} alt="Prostate MRI Heading"/>
                            <p>
                                Prostate MRI is one of the most advanced techniques available to evidence-based science to
                                screen and stage prostate cancer. If you or a loved one are concerned about the risk of
                                developing prostate cancer we encourage you to talk to your doctor about Prostate MRI
                                screening and staging.
                            </p>
                        </div>
                    </div>
                    <HeroImageContainer imageUrl={prostateMriHome} />
                    <div id="tulsaStart"></div>
                </div>
            </div>
            <div className="container-inner pt4 pb4 mb4 mt4" id="mriStart">
                <fieldset className="npb5">
                    <legend><h2 className="text-center">Accuracy in Diagnosing Prostate Cancer</h2></legend>
                    <div className="info-container">
                        <div className="info-section">
                            <img src={accuracyMRI} alt="MRI Accuracy" />
                            <div>
                                <h3 className="f4">MRI Accuracy</h3>
                                <p className="f5">In a study comparing the accuracy of an MRI scan to a biopsy, an MRI scan correctly diagnosed 93% of patients.<sup>1</sup></p>
                            </div>
                        </div>
                        <div className="info-section">
                            <img src={accuracyBiopsy} alt="Biopsy Accuracy" />
                            <div>
                                <h3 className="f4">Biopsy Accuracy</h3>
                                <p className="f5">Whereas a biopsy correctly diagnosed 48% of the patients observed.<sup>2</sup></p>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <p className="text-right"><small><b>1-2</b> Canadian Medical Association Journal, 2019</small></p>
            </div>
            <div className="container-inner pt4" id="mriStart">
                <h2 className="text-center">Pathways to be a Candidate for Prostate MRI</h2>
                <div className="flex flex-column flex-row-ns pt4">
                    <div className="w-100 w-third-ns pa3 text-center">
                        <img src={circle1} alt="Number 1"/>
                        <h4>DETECTION PATHWAY</h4>
                        <p>
                            The first pathway access is for patients 
                            who have an unexplained serum PSA, 
                            and have had at least one negative 
                            TRUS biopsy performed by a Urologist.
                        </p>
                        <p>
                            For these men, we offer a prostate 
                            cancer detection screen on a 3T 
                            magnet. T-2, DCE and DWI sequences 
                            are used to image the prostate. We do 
                            not feel the need to use an endorectal 
                            coil with our superior equipment to 
                            evaluate whether a patient’s prostate 
                            gland has a hidden cancer, or is 
                            normal.
                        </p>
                    </div>
                    <div className="w-100 w-third-ns pa3 text-center">
                        <img src={circle2} alt="Number 2"/>
                        <h4>STAGING PATHWAY</h4>
                        <p>
                            The second pathway is for men who 
                            have had a positive TRUS biopsy and 
                            know they have prostate cancer. Our 
                            STAGING protocol includes the same 
                            sequences as the detection protocol, 
                            with the addition of an endorectal coil 
                            for enhanced resolution and MRI 
                            spectroscopy. This allows us to 
                            precisely measure the tumor's size, 
                            extent, and activity. We can also 
                            determine if the cancer is confined 
                            within the gland or has spread beyond 
                            the capsule. Significant extracapsular 
                            extension often indicates that surgery 
                            may not be the best option, and 
                            radiation therapy might be a more 
                            suitable treatment.
                        </p>
                    </div>
                    <div className="w-100 w-third-ns pa3 text-center">
                        <img src={circle3} alt="Number 3"/>
                        <h4>RECURRENCE PATHWAY</h4>
                        <p>
                            The third pathway is for men who have 
                            been previously treated for prostate 
                            cancer with either surgery or radiation 
                            and have a PSA relapse.
                        </p>
                        <p>
                            Prostate MRI is useful to help identify 
                            where and how big a recurrent or 
                            metastatic focus of prostate cancer they 
                            may have.
                        </p>
                    </div>
                </div>
            </div>
            <div className="mb5 mt5 text-center">
                <div className="image-container">
                    <img className="drPrincenthal" src={drPrincenthal} alt="Dr. Princenthal"/>
                    <div className="legend">
                        <p>"The main value of obtaining a prostate MRI is that it can help distinguish which men would benefit from a biopsy to evaluate a rising PSA, versus which men likely don't have cancer and can avoid a biopsy."</p>
                        <br/>
                        <p><strong>Robert Princenthal M.D.</strong><br/>
                        Radiologist, Prostate Medical Director</p>
                    </div>
                </div>
            </div>
            <section className="mb6 pt1 mri-bg">
                <div className="container-inner mt5 pr-4">
                    <h2>Prostate MRI Background</h2>
                    <p className="main-desc">
                        MRI (magnetic resonance imaging) is a medical imaging technique that allows 
                        doctors to view detailed information on tissue and blood patterns found in the 
                        human body. Through the utilization of high-powered magnets a MRI machine 
                        can cause tissues in the body, include theprostate, to assume different 
                        appearances, thus revealing abnormal tissue.
                    </p>
                    <p>
                        Our dedicated Prostate MRI Program, using a multi-parametric approach to imaging, has shown 
                        increased sensitivity and specificity for cancer detection (around 85 – 90 percent).
                    </p>
                    <ul>
                        <li>These improved results are based by critical analysis of four (4) specific imaging sequences.</li>
                        <li>These include high anatomic quality T-2 weighted imaging and three functional sequences.</li>
                    </ul>
                    <p>The functional sequences include:</p>
                    <ul>
                        <li>
                            DWI (diffusion weighted imaging) analyzes the restriction of movement of water molecules 
                            seen with prostate cancer. DWI is quite accurate and correlates when positive to a specific 
                            Gleason Score upon a positive biopsy result.
                        </li>
                        <li>
                            DCE (dynamic contrast enhancement), where a small amount of IV agent is injected to 
                            evaluate increased and abnormal bloodvessels seen with prostate cancer. These DCE images 
                            are obtained rapidly and analyzed with computer generated curves which look for increased 
                            flow and rapid washout.
                        </li>
                        <li>
                            Images are obtained rapidly, 3 seconds per acquisition and reviewed to generate time function curves.
                        </li>
                    </ul>
                </div>
            </section>
            <section className="mri-benefits">
                <div className="flex flex-column flex-row-l">
                    <div className="w-100 w-50-l pa3 bg-img"></div>
                    <div className="w-100 w-50-l pa3 purple-bg">
                        <div>
                            <h2>Benefits of Prostate MRI</h2>
                            <p>
                                Data from multiple early implementers of prostate MRI have explored the efficacy of prostate MRI and subsequent MRI-guided biopsy.
                            </p>
                            <p>
                                They found that the specificity and sensitivity for finding clinically significant prostate cancer (those with a Gleason score of 6 or higher and a volume exceeding 5 millimeters) was between 85 percent and 96 percent. Their data also show a negative predictive value around 95 percent, which means there was a 95 percent chance that no significant prostate tumor existed if prostate MRI did not identify a tumor.
                            </p>
                            <p>
                                If a tumor nodule was identified, it could be confirmed with a targeted, MRI-guided biopsy. The cancer detection rate for MRI directed biopsy is around 80 percent compared to 45 percent at time of first TRUS biopsy.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mri-points mt6">
                <div className="container-inner mt5 mb6">
                    <h2 className="text-center">
                        Prostate MRI provides several important<br/>
                        data points about a prostate cancer tumor(s).
                    </h2>
                    <div className="flex flex-column flex-row-l">
                        <div className="w-100 w-50-l pa3">
                            <p>IT CAN:</p>
                            <ul>
                                <li>Measure the extent of tumor volume.</li>
                                <li>Identify the tumor’s location or locations in the gland.</li>
                                <li>Estimate how aggressive the tumor is, which is critical to planning accurate biopsy guidance.</li>
                                <li>Reveal staging information.</li>
                                <li>Determine whether the tumor has spread beyond the prostate gland.</li>
                                <li>Roughly 299,010 men will be diagnosed with prostate cancer this year - 35,250 men will die of prostate cancer.<sup>3</sup></li>
                                <li>There are more than 1 million prostate biopsies being performed each year.</li>
                            </ul>
                        </div>
                        <div className="w-100 w-50-l pa3">
                            <img className="" src={mriPoints} alt="Couple"/>
                        </div>
                    </div>
                    <p className="text-right"><small><b>3</b> American Cancer Society: 2024 Key Statistics for Prostate Cancer</small></p>
                </div>
            </section>
            <div className="for-physicians container-inner mb6">
                <h2 className="text-center">For Physicians</h2>
                <p>The RadNet Prostate MRI Program offers referring physicians the ability to enhance and improve their local TRUS biopsy program. We hope the prostate MRI information on this website is helpful to the medical community. The protocols established by the MRI Program can help to assist with a higher yield of diagnostic accuracy, reduce infections, and promote fewer complications. The Prostate Program works in conjunction with your practice to provide referring physicians more detailed information to make treatment choices for their patients.</p>
                <p>The Program will send key color images to referring physicians to help guide medical providers through the report. Follow-up conversations are available with the reading radiologists and/or a Program co-director upon request. The Program’s protocols include:</p>
                <ul>
                    <li>Detection Protocol:&nbsp;for patients with a negative TRUS biopsy and unexplained elevated PSA. The scan will consist of protocols T2 imaging, DCE and DWI. No endo&nbsp;coil, no spectroscopy. Routine MRI safety screen plus an IV contrast prep.</li>
                    <li>Prostate Cancer Staging Protocol:&nbsp;for patients who have had a recent biopsy proving cancer. In addition to the detection protocol the scan will be performed with an endo&nbsp;coil for optimal resolution and MR spectroscopy and IV contrast for the DCE sequences. This protocol should be used for active surveillance patients.</li>
                    <li>Bones and Nodes Protocol:&nbsp;if a patient has a high pretest probability of metastatic prostate cancer we will perform a Bones and Nodes Protocol. This includes imaging the pelvis, lumbar spine and thoracic spine with T1 weighted images and diffusion/STIR images looking for bone metastasis and abnormal lymph nodes.</li>
                    <li>Recurrence Protocol:&nbsp;for patients with rising PSA following either RT or surgery.</li>
                </ul>
            </div>
            <div className="mb6">
                <HearFromYouForm/>
            </div>
            <div className="pre-footer">
                    <div className="mw9 center ph3-ns">
                        <div class="flex flex-wrap justify-between items-center ph2-ns">
                        <div class="fl w-20-l w-100 pa2 flex items-center justify-center">
                                <img src={physicianIcon} alt="TULSA Physician Icon"/>
                            </div>
                            <div class="fl w-80-l w-100 pa2 flex items-center justify-center">
                                <div>
                                    <p>
                                        Speak to your primary care physician or your urologist if you believe that you may 
                                        benefit from prostate MRI or MRI-guided prostate biopsy. A prescription is required to 
                                        schedule a prostate MRI procedure. Thank you for your interest in RadNet. Please let us 
                                        know how we can connect with you.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </Layout>
        <div className="contact-section-wrapper">       
                    <div className="contact-section-container">
                        <HearFromYouForm />
                    </div>
                </div>
        </>
    )
}

export default ProstateMRI
